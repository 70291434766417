import { Button, Result } from 'antd';
import React from 'react';

const title = 'Not Found';

function Page404() {
  document.title = title;
  return (
    <div className="main-content">
      <Result
        status="404"
        title={<h2 className="display-5 mt-2">AMES ENGLISH</h2>}
        subTitle="Sorry, the page you visited does not exist."
        extra={
            <Button type="primary" onClick={()=>{
              window.location.href = window.location.origin;
            }}>Back Home</Button>
        }
      />
    </div>
  );
}

export default Page404;
